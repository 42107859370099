import { msalInstance } from "./App";

const getAccessToken = async () => {
    const request = {
        scopes: ["api://d4f8b85a-2b2b-4a07-a55c-7de1d29f9462/.default"], // Replace with your actual API scope
    };

    try {
        const response = await msalInstance.acquireTokenSilent(request);
        return response.accessToken;
    } catch (error) {
        if (error.name === "InteractionRequiredAuthError") {
            const response = await msalInstance.acquireTokenPopup(request);
            return response.accessToken;
        } else {
            console.error("Token acquisition failed:", error);
            throw error;
        }
    }
};

// Generic fetch wrapper for making API calls
export const apiFetch = async (endpoint, options = {}) => {
    const token = await getAccessToken();

    const headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    };

    return fetch(endpoint, { ...options, headers });
};
