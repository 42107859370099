function APIGetForm({dealId, setDealId, deal, setDeal, accountTxId, setAccountTxId, setFile, fileName, setFileName, orderJson,
                        setOrderJson, verified, handleVerified, handleLoad, isLoadAttempted, setIsLoadAttempted, setVerified}) {


    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const getInvoicesByAccountTrxId = () => {
        if (orderJson && isLoadAttempted) {
            return <span className="file-name">
               {orderJson['orderNumber']}
           </span>
        }

        let failureSnippet = <></>
        if (isLoadAttempted && !orderJson) {
            failureSnippet = <span className="error">Invoice not found</span>
        }

        return <>
            {/*<button disabled={verified && deal} onClick={verify} style={{width: 100}}>Load</button>*/}
            <button onClick={handleLoad} style={{width: 100}}>Load</button>
            {failureSnippet}
        </>
    }

    const dealHelpText = () =>
        fileName ? fileName : "File name here after attached (upload confirmation)."

    const verify = () => {
        handleVerified()
    }

    const getDealNameOrVerify = () => {
        if (deal && deal['properties']) {
            return <span className="file-name">
               {deal['properties']['dealname']}
           </span>
        }

        let failureSnippet = <></>
        if (verified && !deal) {
            failureSnippet = <span className="error">Deal not found</span>
        }

        return <>
            <button disabled={verified && deal} onClick={verify} style={{width: 100}}>Verify</button>
            {failureSnippet}
        </>
    }

    return (
        <form className="order-form" onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="deal-id">
                    Enter Deal ID
                    <span className="label-secondary">Record ID from HubSpot</span>
                </label>
                <div style={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
                    <input
                        type="text"
                        id="deal-id"
                        className="order-form-input-text"
                        value={dealId}
                        style={{}}
                        onChange={(e) => {
                            setDealId(e.target.value)
                            setDeal(null)
                            setVerified(false)
                        }}
                        placeholder="Record ID from HubSpot"
                    />
                    {getDealNameOrVerify()}
                </div>
            </div>

            <div className="gray-container">
                <div className="form-group">
                    <label htmlFor="deal-id">
                        Enter Account Transaction ID
                        <span className="label-secondary">Account Transaction ID</span>
                    </label>
                    <div style={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
                        <input
                            type="text"
                            id="deal-id"
                            className="order-form-input-text"
                            value={accountTxId}
                            style={{}}
                            onChange={(e) => {
                                setIsLoadAttempted(false);
                                setAccountTxId(e.target.value);
                            }}
                            placeholder="Account Transaction ID"
                        />
                        {getInvoicesByAccountTrxId()}
                    </div>
                </div>
            </div>

        </form>
    );

}

export default APIGetForm;
