import React, {useEffect, useRef, useState} from 'react';
import * as CryptoJS from 'crypto-js'
import {withMsal} from "@azure/msal-react";

const Nav = ({ msalContext }) => {

    const { accounts, instance } = msalContext;
    const isAuthenticated = accounts && accounts.length > 0;
    const userInfo = isAuthenticated ? accounts[0] : {};

    // const [userInfo, setUserInfo] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const e7HeaderLogo= process.env.PUBLIC_URL + '/e7-horizontal-white-logo.svg'

    const handleLogout = async () => {
        try {
            await instance.logoutRedirect()
            // await instance.logoutRedirect();
        } catch (error) {
            console.error("Logout failed", error);
        }
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    // Close dropdown when clicking outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [dropdownRef]);

    const loggedOut = () => {
        return (
            <div className="user-info">
            </div>
        );
    }

    const getGravatarLink = () => {
        const hash = CryptoJS.MD5(userInfo.username).toString();
        return "https://www.gravatar.com/avatar/" + hash
    }

    const loggedIn = () => {
        return (
            <div className="user-section" ref={dropdownRef}>
                <img src={getGravatarLink()}  alt="avatar" className="gravatar"/>
                <button onClick={toggleDropdown} className="user-button">
                    <span className="header-footer-text">{userInfo.name} ▼</span>
                </button>
                {isDropdownOpen && (
                    <div className="dropdown-menu">
                        <div className="dropdown-item">{userInfo.username}</div>
                        <button onClick={handleLogout}>Log Out</button>
                    </div>
                )}
            </div>
        );
    }

    return (
        <header>
            <div className="header-container">

                <div className="logo-container">
                    <img src={e7HeaderLogo} alt="E7 Solutions" height="10" />
                    <span className="largish header-footer-text"> | AQuA</span>
                </div>

                <div className="user-menu">
                {isAuthenticated? loggedIn() : loggedOut()}
                </div>

            </div>
        </header>
    );
};

export default withMsal(Nav);