import React from 'react';
import {Outlet} from 'react-router-dom';
import {useMsalAuthentication, withMsal} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import Loading from "./Loading";

export const RequiredAuth = ({msalContext}) => {
    const { accounts, instance } = msalContext;
    const isAuthenticated = accounts && accounts.length > 0;

    const { result, error } = useMsalAuthentication(InteractionType.Redirect, {
        ...{ scopes: ["User.Read"] },
        redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI, // e.g. /redirect
    });

    if (!isAuthenticated) {
        return (<Loading />);
    }

    return ( <Outlet /> );
}

export default withMsal(RequiredAuth);