import React from "react";
import {useNavigate, useOutletContext} from "react-router-dom";


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const Step1 = () => {

    const navigate = useNavigate();

    const [
        deal,
        setDeal,
        dealId,
        setDealId,
        file,
        setFile,
        fileName,
        setFileName,
        discounts,
        setDiscounts,
        orderJson,
        setOrderJson,
    ] = useOutletContext();

    const handleSubmit = next => {
        navigate(next)
    }

    const MultiActionAreaCard = (title, message, image, next) => {
        return (
            <Card sx={{ maxWidth: 345 }}>
                <CardActionArea onClick={() => handleSubmit(next)}>
                    {/*<cardmedia*/}
                    {/*    component="img"*/}
                    {/*    height="340"*/}
                    {/*    image={image}*/}
                    {/*    alt={title}*/}
                    {/*/>*/}

                    <CardContent>
                        {/*<Typography gutterBottom variant="h5" component="div">*/}
                        {/*    {title}*/}
                        {/*</Typography>*/}
                        <Typography variant="body2" color="text.secondary">
                            {message}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={2}/>
                <Grid xs={4}>
                    {MultiActionAreaCard("File", "my.atlassian (JSON) [OLD]", "json.png", "step1_file")}
                </Grid>
                <Grid xs={4}>
                    {MultiActionAreaCard("API", "Partner Purchasing Center (PPC API) [NEW]", "api.png", "step1_api")}
                </Grid>
                <Grid xs={2}/>
            </Grid>
        </>
    );
}

export default Step1