import React, {useEffect, useState} from "react";
import QuoteForm from "../Forms/QuoteForm";
import {useNavigate, useOutletContext} from "react-router-dom";
import {apiFetch} from "../../apiService";

const Step1_API = () => {
    const navigate = useNavigate();

    const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;

    const [
        deal,
        setDeal,
        dealId,
        setDealId,
        file,
        setFile,
        fileName,
        setFileName,
        discounts,
        setDiscounts,
        orderJson,
        setOrderJson,
    ] = useOutletContext();

    const [verified, setVerified] = useState(deal != null && deal.hasOwnProperty('properties'));

    const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

    useEffect(() => {
        setIsReadyToSubmit(verified && file && deal);
    }, [verified, file, deal]);

    useEffect(() => {
        setVerified(false);
        setDeal(null);
    }, [dealId]);

    const handleSubmit = _ => {
        if (isReadyToSubmit) {
            navigate("../step2")
        }
    }

    const handleVerify = _ => {
        if (dealId && dealId > 0) {
            apiFetch(`${SERVER_API_URL}/api/verifyHSOrderId/${dealId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-store", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin" // include, *same-origin, omit
            })
                .then(response => {
                    if (response.status === 200) {
                        response.json().then(json => {
                            setDeal(json)
                            setVerified(true)
                        })
                    } else {
                        setVerified(true)
                        setDeal(null);
                    }
                })
                .catch(err => {
                    setVerified(true);
                    setDeal(null)
                    console.error(err)
                });
        }
    }

    return (
        <>
            <div>
                <h2>Step 1</h2>
                <span>Begin by uploading the Atlassian JSON file associated with your quote, enter the associated Deal ID from HS, and specify any additional discounts. Press Confirm to continue.</span>
            </div>
            <QuoteForm dealId={dealId} setDealId={setDealId} deal={deal} file={file} setFile={setFile}
                       fileName={fileName} setFileName={setFileName} setOrderJson={setOrderJson} verified={verified}
                       handleVerify={handleVerify}/>
            <div className="action-buttons-container">
                {/*<button disabled={verified} onClick={handleVerify}>Verify</button>*/}
                <button disabled={!isReadyToSubmit} onClick={handleSubmit}>Confirm</button>
            </div>
        </>
    );
}

export default Step1_API