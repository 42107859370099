import {apiFetch} from "../../apiService";

function QuoteForm({dealId, setDealId, deal, setFile, fileName, setFileName, setOrderJson, verified, handleVerify}) {
    const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;

    const handleSubmit = (event) => {
        event.preventDefault();
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            setFile(file);

            const fileReader = new FileReader();
            fileReader.readAsText(event.target.files[0], "UTF-8");
            fileReader.onload = e => {
                // noinspection JSCheckFunctionSignatures
                const parsedJson = JSON.parse(e.target.result);
                apiFetch(`${SERVER_API_URL}/api/convert/my-atlassian`, {
                    method: "POST",
                    body: JSON.stringify(parsedJson),
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-store", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin" // include, *same-origin, omit
                })
                    .then(response => response.json() )
                    .then(json => {
                        return setOrderJson(json)
                    })
                    .catch(err => console.error(err));
            };
        } else {
            setFileName('');
            setFile(null);
        }
    };

    const getDealNameOrVerify = () => {
        if (deal && deal['properties']) {
            return <span className="file-name">
               Deal Name: {deal['properties']['dealname']}
           </span>
        }

        let failureSnippet = <></>
        if (verified && !deal) {
            failureSnippet = <span className="error">Deal not found</span>
        }

        return <>
            <button disabled={verified && deal} onClick={verify} style={{width: 100}}>Verify</button>
            {failureSnippet}
        </>
    }

    const dealHelpText = () =>
        fileName ? fileName : "File name here after attached (upload confirmation)."

    const verify = () => {
        handleVerify()
    }

    return (
        <form className="order-form" onSubmit={handleSubmit}>
            <div className="gray-container">
                <div className="form-group">
                    <label htmlFor="upload-quote">Upload Quote
                        <span className="label-secondary">JSON File from Atlassian</span>
                    </label>
                    <div style={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
                        <button
                            onClick={() => document.getElementById('hidden-file-input').click()}
                            className="custom-file-upload"
                        >
                            Browse...
                        </button>
                        <input
                            type="file"
                            id="hidden-file-input"
                            onChange={handleFileChange}
                            style={{display: 'none'}}
                        />
                        <span className="file-name">
                          {dealHelpText()}
                        </span>
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="deal-id">
                        Enter Deal ID
                        <span className="label-secondary">Record ID from HubSpot</span>
                    </label>
                    <div style={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
                        <input
                            type="text"
                            id="deal-id"
                            className="order-form-input-text"
                            value={dealId}
                            style={{}}
                            onChange={(e) => setDealId(e.target.value)}
                            placeholder="Record ID from HubSpot"
                        />
                        {getDealNameOrVerify()}
                    </div>
                </div>
            </div>
        </form>
    );

}

export default QuoteForm;
