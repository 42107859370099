import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import Home from "./components/Home";
import Step1_API from "./components/Steps/Step1_API";
import Step2 from "./components/Steps/Step2";
import DealUploadWizard from "./components/Steps/DealUploadWizard";
import Step3 from "./components/Steps/Step3";
import Success from "./components/Steps/Success";
import Step0 from "./components/Steps/Step0";
import Step1_JSON from "./components/Steps/Step1_JSON";
import RequiredAuth from "./components/SecureRoute";

const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/protected' element={<RequiredAuth/>}>
                <Route path='' element={<DealUploadWizard/>}>
                    <Route index element={<Step0/>}/>
                    <Route path='step1_api' element={<Step1_API/>}/>
                    <Route path='step1_file' element={<Step1_JSON/>}/>
                    <Route path='step2' element={<Step2/>}/>
                    <Route path='step3' element={<Step3/>}/>
                    <Route path='success' element={<Success/>}/>
                </Route>
            </Route>
        </Routes>
    );
}

export default AppRoutes;