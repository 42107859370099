import './App.css';

import React, {useEffect} from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Routes from "./Routes";
import {MsalProvider, useMsal} from "@azure/msal-react";
import {createStandardPublicClientApplication} from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: "058b85b2-5bb4-4464-accb-c966f0a9829e",
        authority: "https://login.microsoftonline.com/7812094e-2a28-4fd6-a842-3c5f8728ff29/v2.0",
        redirectUri: process.env.REACT_APP_REDIRECT_URI || "http://localhost:3000",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
};
console.log(msalConfig)
export const msalInstance = await createStandardPublicClientApplication(msalConfig);

const SetActiveAccount = () => {
    const { instance, accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0) {
            instance.setActiveAccount(accounts[0]); // Set the first account as active
        }
    }, [accounts, instance]);

    return null; // No UI needed for this component
};

const App = () => {
    return (
        <MsalProvider instance={msalInstance}>
            <SetActiveAccount />
            <Header/>
            <main className="main-content">
                <Routes/>
            </main>
            <Footer/>
        </MsalProvider>
    );
}

export default App;